
import {
  defineComponent,
  getCurrentInstance,
  reactive,
  toRefs,
  onMounted,
  onUnmounted,
} from "vue";
import AMapLoader from "@amap/amap-jsapi-loader";
import { useRouter } from "vue-router"; //引入路由
export default defineComponent({
  name: "WebGis",
  components: {},
  setup() {
    const { proxy }: any = getCurrentInstance();
    const router = useRouter();

    let data: any = reactive({
      map: {},
      websock: {},
    });
    const FnInitMap = function () {
      AMapLoader.load({
        key: proxy.vuexWebGisSetting.key, // 申请好的Web端开发者Key，首次调用 load 时必填
        version: proxy.vuexWebGisSetting.version, // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        AMapUI: {
          // 是否加载 AMapUI，缺省不加载
          version: proxy.vuexWebGisSetting.versionForAMapUI, // AMapUI 缺省 1.1
          plugins: [], // 需要加载的 AMapUI ui插件
        },
        Loca: {
          // 是否加载 Loca， 缺省不加载
          version: proxy.vuexWebGisSetting.versionForLoca, // Loca 版本，缺省 1.3.2
        },
      })
        .then((AMap) => {
          data.map = new AMap.Map("gis", {
            zoom: proxy.vuexWebGisSetting.zoom, //级别
            center: proxy.vuexWebGisSetting.center, //中心点坐标
            viewMode: proxy.vuexWebGisSetting.viewMode, //使用3D视图
          });
        })
        .catch((e) => {
          console.log(e);
        });
    };
    //初始化weosocket
    function initWebSocket() {
      if (typeof WebSocket === "undefined") {
        alert("您的浏览器不支持WebSocket");
        return false;
      }
      const wsuri = "ws://http://172.0.0.1:2345"; // websocket地址
      data.websock = new WebSocket(wsuri);
      data.websock.onopen = websocketonopen;
      data.websock.onmessage = websocketonmessage;
      data.websock.onerror = websocketonerror;
      data.websock.onclose = websocketclose;
    }
    //连接成功
    function websocketonopen() {
      console.log("WebSocket连接成功");
      // 添加心跳检测，每30秒发一次数据，防止连接断开（这跟服务器的设置有关，如果服务器没有设置每隔多长时间不发消息断开，可以不进行心跳设置）
      let self = data;
      data.timer = setInterval(() => {
        try {
          self.websock.send("test");
          console.log("发送消息");
        } catch (err) {
          console.log("断开了：" + err);
          self.connection();
        }
      }, 30000);
    }
    //接收后端返回的数据，可以根据需要进行处理
    function websocketonmessage(e: any) {
      let data1Json = JSON.parse(e.data);
      console.log(data1Json);
    }
    //连接建立失败重连
    function websocketonerror(e: any) {
      console.log(`连接失败的信息：`, e);
      data.initWebSocket(); // 连接失败后尝试重新连接
    }
    //关闭连接
    function websocketclose(e: any) {
      console.log("断开连接", e);
    }
    onMounted(() => {
      FnInitMap();
      initWebSocket();
    });
    onUnmounted(() => {
      //页面销毁时关闭ws连接
      if (data.websock) {
        data.websock.close(); // 关闭websocket
      }
    });
    return {
      ...toRefs(data),
      proxy,
      router,
      FnInitMap,
    };
  },
});
